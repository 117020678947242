import React from "react";
import { Routes, Route } from "react-router-dom";
import Logout from "./Pages/Logout/logout";

// Import layout components
import Header from "components/Layout/Header";
import Sidebar from "components/Layout/Sidebar";

// Import pages
import Home from "containers/Pages/Home";
import Add from "containers/Pages/Add";
import Translation from "containers/Pages/Translation";

const App = (props) => {
	return (
		<div>
			<Sidebar />

			<div className="wrapper d-flex flex-column min-vh-100 bg-light">
				<Header />

				<div className="body flex-grow-1 px-3">
					<Routes>
						<Route path="/logout" element={<Logout />} />
						<Route index element={<Home />} />
						
						<Route path="translation/:id" element={<Translation />} />
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default App;
