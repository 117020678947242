import { Form, Field } from "react-final-form";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Box,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import flagSE from "assets/flag-se.svg";
import flagDK from "assets/flag-dk.svg";
import flagNO from "assets/flag_no.svg";
import flagUK from "assets/flag-uk.svg";
import flagGB from "assets/flag-gb.svg";
import { useEffect, useState } from "react";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const EditTranslationsRow = (props) => {
  
  
  const validateInput = (value) => {
    

    if (!value || value.trim() === '') {
      return 'Input empty';
    }
    if (value.length < 1) {
      return 'Input value is too short';
    }
    

    return undefined;
  };
  
  const { translation } = props;

  const lcidFlags = {
    "sv-SE": flagSE,
    "da-DK": flagDK,
    "nb-NO": flagNO,
    "en-US": flagGB,
    "uk": flagUK,
  };

  return (
    <Form
      onSubmit={(value) => {
        
        props.onSubmit(value);
      }}
      initialValues={translation}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: "#CFD8DC",
                    width: "5%",
                    border: "0px"
                  }}
                >
                  {lcidFlags[translation.lcid] ? (
                    <Tooltip title={`LCID: ${translation.lcid}`} arrow>
                      <div>
                        <img
                          src={lcidFlags[translation.lcid]}
                          height={50}
                          width={70}
                          alt={translation.lcid}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <div>{translation.lcid}</div>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '10px', border: '0px' }}>
                  <Field
                    name="text"
                    validate={validateInput}
                    render={({ input, meta }) => (
                      <Box>
                        <TextField
                          {...input}
                          fullWidth
                          variant="outlined"
                           
                          placeholder="No translation"
                        />
                      </Box>
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      )}
    />
  );
};

export default EditTranslationsRow;
