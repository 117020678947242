import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	useSearchTranslationMutation,
	useGetMissingTranslationsQuery,
} from "features/translations/api";
import {
	CTable,
	CTableBody,
	CTableDataCell,
	CTableHead,
	CTableHeaderCell,
	CTableRow,
} from "@coreui/react";
import SearchForm from "containers/Forms/search";

const Home = () => {
	const activeService = useSelector((state) => state.selectedService);
	const missingTranslations = useGetMissingTranslationsQuery(activeService);
	const [searchById, { data: result, reset: resetSearchResult }] = useSearchTranslationMutation();

	const onSubmit = (value) => {
		console.log(value.id)
		searchById(value.id);
		
	};

	useEffect(() => {
		resetSearchResult();
	}, [activeService.service, activeService.servicePart]);
	
	return (
		<div>
			{activeService.service != null ? (
				<h1>Search for translation</h1>
			) : (
				<h1>Search for translation</h1>
			)}

			<SearchForm onSubmit={onSubmit}></SearchForm>

			<CTable>
				<CTableHead>
					<CTableRow>
						<CTableHeaderCell>ID</CTableHeaderCell>
					</CTableRow>
				</CTableHead>

				<CTableBody>
					{result != null
						? result.map((translation) => {
							return (
								//Return searchresult
								<CTableRow key={translation}>
									<CTableDataCell>
										<Link to={`translation/${translation.id}`}>
											{translation.id}
										</Link>
									</CTableDataCell>
								</CTableRow>
							);
						})
						: null}
				</CTableBody>

				{activeService.service != null ? (
					<h1>
						Missing translations for: {activeService.service?.toLowerCase()},{" "}
						{activeService.servicePart?.toLowerCase()}
					</h1>
				) : (
					<h1>Missing translations</h1>
				)}
			</CTable>

			<CTable>
				<CTableBody>
					
					{missingTranslations.data != null
						? missingTranslations.data.map((translation) => {
							return (
								//Return searchresult
								<CTableRow key={translation}>
									<CTableDataCell>
										<Link to={`translation/${translation}`}>
											{translation}
										</Link>
									</CTableDataCell>
								</CTableRow>
							);
						})
						: null}
				</CTableBody>
			</CTable>
		</div>
	);
};

export default Home;
