import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	useAddTranslationMutation,
	useUpdateByIdMutation,
	useDeleteTranslationMutation,
	useGetTranslationByIdQuery,
	useGetLcidsQuery,
	
} from "features/translations/api";
import {
	Button,
	Card,
	CardContent,
	Typography,
	Grid,
	Snackbar,
	Alert,
} from "@mui/material";
import EditTranslationsRow from "containers/Forms/edittranslationrow";
import dayjs from "dayjs";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AddIndex from "../Add/index";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const Translation = () => {
	let params = useParams();
	const id = params.id;
	const formRef = useRef(null);
	

	const [createTranslation] = useAddTranslationMutation();
	const [updateByRowId] = useUpdateByIdMutation();
	const [deleteByRowId] = useDeleteTranslationMutation();
	const [copy, setCopy] = useState([]);
	
	const [selectedService, setSelectedService] = useState("HALDOR_EDUCATION");
	const [selectedServicePart, setSelectedServicePart] = useState("FRONTEND");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [copyMessage, setCopyMessage] = useState(false);
	const [pasteState, setPasteState] = useState([]);
	const [pasteUsed, setPasteUsed] = useState(false);
	const [copyUsed, setCopyUsed] = useState(false);
	const { data: selectedTranslation, isLoading, refetch } = useGetTranslationByIdQuery(id);
	
	const { data: lcids } = useGetLcidsQuery();

	const serviceParts = ["BACKEND", "FRONTEND"];
	const services = [
		"HALDOR_EDUCATION",
		"HALDOR_GUARDIANS",
		"HALDOR_PLAGIARISM",
	];

	const requiredLcid = [
		'da-DK', 'en-US', 'nb-NO', 'sv-SE', 'uk'
	];

	
	
	const onSubmit = async (value) => {
		console.log("Sending this object to the API:", value);
		if (!value.text) return;
		if (!value.rowId || value.rowId === 0) {
			delete value.creator;
			delete value.created;
			delete value.editor;
			delete value.edited;
			delete value.autoTranslated;
			console.log(value)
			console.log("Creating form")
			try {
				const responseFromCreate = await createTranslation(value);
				console.log("Response from createTranslation:", responseFromCreate);
				if(responseFromCreate){
					console.log("refetched")
					refetch();
				}
			} catch (error) {
				console.error("Error creating translation:", error);
			}
		} else {
 	 		updateByRowId(value);
			refetch();
			console.log("Updating form")
		}
		setOpenSnackbar(true);
	};

	const onDelete = (rowId) => {
		
		console.log(rowId)
	};
	
	
	
	const onSubmitRows = (event) => {
		console.log(1)
		event.preventDefault();
		const forms = formRef.current.querySelectorAll("form");
		// const forms = event.target.closest(".card-body").querySelectorAll("form");
		
		forms.forEach((form) => {
			form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
		});
		
	};
	//Knappar
	const [showAllLanguages, setShowAllLanguages] = useState(false);
	const handleServiceClick = (service) => setSelectedService(service);
	const handleServicePartClick = (servicePart) => setSelectedServicePart(servicePart);

	const handleToggle = () => {
		setShowAllLanguages(prevState => !prevState);
	};

	const copyTranslation = (translations) => {		
		const translationCopy = translations.filter(
		(item) => item.service === selectedService && item.servicePart === selectedServicePart).map((item) => 
		({
				...item,
				rowId: 0,
		}));
		if(translationCopy.length > 0)
		{
			console.log(translationCopy)
			setCopy(translationCopy); 
			setCopyMessage(true); 
			setCopyUsed(true)			
		}		
		setPasteUsed(false)
	};
	
	// const copyTranslation = (translation) => {
	//	const forms = formRef.current.querySelectorAll("form");

	
	// 	setCopy(translation); 
	// 	setCopyMessage(true); 
	// 	setCopyUsed(true)
	// 	setPasteUsed(false)
	// };

	const pasteCopy = () => {
		console.log(copy);
		console.log(copy[0].servicePart)
		console.log(copy[0].service)
		if (
			copy && 
			copy.length > 0 && 
			(copy[0].servicePart !== selectedServicePart || copy[0].service !== selectedService)
		) {
			const updatedCopy = copy.map((item) => ({
				...item,
				service: selectedService,
				servicePart: selectedServicePart,
			  }));
			  console.log(updatedCopy)


			const translationPaste = copy.map((item) => ({
				...item,
				service: selectedService,
				servicePart: selectedServicePart,
				
			}));
			
			console.log(translationPaste);
	
			setPasteState(translationPaste);
			setPasteUsed(true);
			setCopyUsed(false);
		} else {
			console.error("Cannot paste into the same servicePart and service!");
		}
	};
	
	  
	
	// console.log("Copy:",copyUsed)
	// console.log("Paste:",pasteUsed)
	
	// console.log(pasteState)
	// console.log(selectedTranslation)
	// console.log(copy)
	
	const filteredLcids = lcids ? (showAllLanguages ? lcids : lcids.filter((lcid) => requiredLcid.includes(lcid))) : [];
	
	if (!selectedTranslation && isLoading ) return <Typography variant="h3">Loading...</Typography>;
	
	return (				
		<div className="form-position">
			<AddIndex />  
			<div className="center">
				<Typography variant="h4" gutterBottom style={{marginTop:"10px",paddingTop:"10px", borderTop: "2px solid #e0e0e0", display:"flex", justifyContent:"space-between"}}>
					<span style={{ color: "#213e7a", marginLeft: "5px" }}>Edit translation for <span style={{textDecoration:"underline"}}>{selectedTranslation[0]?.id}</span></span>
					{/* <Grid item>
						<Button
						onClick={() => onDelete(selectedTranslation[0].rowId)}
						
						color="red"
						style={{ margin: "5px 0px" }}
						>
						Delete translation X
						</Button>
					</Grid> */}
				</Typography>
				
				<div>
					{services.map((service) => (
						<Button
							key={service}
							
							variant={service === selectedService ? "contained" : "outlined"}
							color="primary"
							onClick={() => handleServiceClick(service)}
							style={{ margin: "5px", padding: "10px", backgroundColor: service === selectedService ? "#213e7a" : "transparent", color: service === selectedService ? "white" : "#213e7a", }}
						>
							{service}
						</Button>
					))}
				</div>

				<div>
					{serviceParts.map((servicePart) => (
						<Button
							
							key={servicePart}
							variant={servicePart === selectedServicePart ? "contained" : "outlined"}
							color="primary"
							onClick={() => handleServicePartClick(servicePart)}
							style={{ margin: "5px", backgroundColor: servicePart === selectedServicePart ? "#213e7a" : "transparent", color: servicePart === selectedServicePart ? "white" : "#213e7a",}}
						>
							{servicePart}
						</Button>
					))}
					
				</div>
				
				<Grid container spacing={3}>
					{services
						.filter((service) => service === selectedService)
						
						.map((service) =>
							serviceParts
								.filter((servicePart) => servicePart === selectedServicePart)
								.map((servicePart) => {

									let lastEditor = null;
									selectedTranslation.forEach((translation) => {
										if (translation.service != service || translation.servicePart != servicePart) {
											return true;
										}

										if (lastEditor == null) {
											lastEditor = translation;
											return true;
										}
										let current = lastEditor.created;
										let next = translation.created;
										
										if (lastEditor.edited != null) {
											current = lastEditor.edited;
										}
										if (translation.edited != null) {
											next = translation.edited;
										}
										if (dayjs(current).isBefore(next)) {
											lastEditor = translation;
										}
									});

									return (
										
										<Grid item xs={12} key={`${service}-${servicePart}`}>
											
											<Card>
												<CardContent ref={formRef} >
													<Typography variant="h6">
														
														{lastEditor ? (
															<span  style={{ color: "#B0B0B0" }}>
																{} Edited:{" "}
																{dayjs
																	.utc(lastEditor.edited || lastEditor.created)
																	.format("YYYY-MM-DD HH:mm")}
																{" by "}
																{lastEditor.editor || lastEditor.creator}
															</span >
														) : (
															<span style={{ color: "#B0B0B0" }}>--Not yet edited</span>
														)}
													</Typography>
													<Grid container spacing={2} alignItems="center">
													<Grid item>
														
														<Button
														onClick={handleToggle}
														variant="contained"
														color="white"
														style={{ margin: "5px 0px" }}
														>
														{showAllLanguages ? "Minimize languages" : "Show All languages"}
														{showAllLanguages ? (
															<ArrowUpwardIcon style={{ marginLeft: "8px", width: 15 }} />
														) : (
															<ArrowDownwardIcon style={{ marginLeft: "8px", width: 15 }} />
														)}
														</Button>
													</Grid>
													<Grid item>
													<Button
															onClick={() => copyTranslation(selectedTranslation)}
															variant="contained"
															color="white"
															style={{ margin: "5px 0px" }}
															>
															Copy
															</Button>
															<Button

															    disabled={!copyUsed}
																onClick={() => pasteCopy()}
																variant="contained"
																color="white"
																style={{ margin: "5px 0px" }}
															>
																Paste
															</Button>
													</Grid>
													
													{/* <Grid item>
														<Button
														
														variant="contained"
														color="white"
														style={{ margin: "5px 0px", backgroundColor:"#213e7a", color:"white" }}
														>
														Bing Translate
														</Button>
													</Grid> */}
													</Grid>

													{filteredLcids.map((lcid) => {
														// Försök att hitta om det finns en kopierad översättning i pasteState
														const pastedTranslation = pasteState.find(
															(item) => item.lcid === lcid && item.service === selectedService && item.servicePart === selectedServicePart
														);

														// Om en kopia finns, använd den, annars använd originalöversättningen från translations
														const translation = pastedTranslation 
															|| selectedTranslation.find(
																(t) => t.lcid === lcid && t.service === selectedService && t.servicePart === selectedServicePart
															) 
															|| { lcid, id, service: selectedService, servicePart: selectedServicePart };

															// Sätt id om det finns existerande översättning
															if (pastedTranslation && Array.isArray(selectedTranslation)) {
																const matchingTranslation = selectedTranslation.find(
																	(t) => t.lcid === lcid && t.service === selectedService && t.servicePart === selectedServicePart
																);
																if (matchingTranslation) {
																	translation.rowId = matchingTranslation.rowId;
																}
															}


														return (
															<div key={lcid}>
															<EditTranslationsRow
																translation={translation}
																onSubmit={onSubmit}
															/>
															</div>
														);
													})}

												</CardContent>
												
												<Button
													fullWidth
													variant="contained"
													color="primary"
													onClick={onSubmitRows}
													sx={{ height: '60px' , backgroundColor: "#213e7a"}}
												>
													Save
												</Button>
											</Card>
										</Grid>
									);
								})
						)}
				</Grid>

				<Snackbar sx={{width:"50%", fontWeight:"bold" }} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openSnackbar} autoHideDuration={5000} onClose={() => setOpenSnackbar(false)}>
					<Alert sx={{ fontWeight:"bold", backgroundColor:"#213e7a",
						color: "white",
						".MuiAlert-icon": {
							color: "white",
						  }}} onClose={() => setOpenSnackbar(false)} severity="success">
						Translation updated successfully!
					</Alert>
				</Snackbar>
				<Snackbar sx={{fontWeight:"bold"}} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={copyMessage} autoHideDuration={5000} onClose={() => setCopyMessage(false)}>
					<Alert sx={{ width: "100%" ,
						fontWeight:"bold" ,
						backgroundColor:"#213e7a",
						color: "white",
						".MuiAlert-icon": {
							color: "white",
						  },
						}} onClose={() => setCopyMessage(false)} severity="success">
						Translation copied.
					</Alert>
				</Snackbar>
			</div>
		</div>
	);
};

export default Translation;

