import React, { useState } from "react";
import Translations from "containers/Forms/translations";
import { useAddTranslationMutation } from "features/translations/api";
import { useNavigate } from "react-router";
import { Typography, Snackbar, Alert } from "@mui/material";

const Add = (props) => {
  const navigate = useNavigate();
  const [addTranslation] = useAddTranslationMutation();
  const [toast, setToast] = useState(false);

  
  const handleToastClose = () => {
    setToast(false);
  };

  const onSubmit = async (value) => {
    console.log(value)
    try {
      const response = await addTranslation(value).unwrap();
      console.log(response)
      if (response) {
        setToast(true)
        setTimeout(() => {
          navigate(`/translation/${response.id}`);
        }, 4000); 
      }
    } catch (error) {
      console.error("Failed to add translation:", error);
    }
    setToast(true)
  };


  return (
    <div>
      <Typography variant="h4">
        Add translation
      </Typography>
      
      <Translations onSubmit={onSubmit}></Translations>
      {/* Snackbar for success notification */}
                <Snackbar
                  open={toast}
                  autoHideDuration={4000}
                  onClose={handleToastClose}
                  anchorOrigin={{ vertical: "button", horizontal: "center" }}  
                  sx={{width:"50%"}}
                >
                  <Alert
                    onClose={handleToastClose}
                    severity="success"
                    sx={{ width: "100%" , fontWeight:"bold", backgroundColor:"#213e7a",
                      color: "white",
                      ".MuiAlert-icon": {
                        color: "white",
                        }}}
                  >
                    Translation added, Loading form...
                  </Alert>
                </Snackbar>
      
    </div>
    
  );
};

export default Add;
